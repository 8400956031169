import React, { useEffect, useRef, useState,useContext } from "react";
import { CompanyContext } from '../../global_setting/Store';
import config from '../../../src/config.json'
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken ="pk.eyJ1IjoiaGFmaXpiaiIsImEiOiJja2s4NDBxMXAwaXB3MzNudjNkZDUxenlwIn0.GU3mEuuyxqczV8tqZWFSAA";

const styles = {
  width:"100%",
  minHeight:"380px"
};

const MapBoxComponent = () => {
  const [map, setMap] = useState(null);
  const mapContainer = useRef(null);
  const [companyDetails] = useContext(CompanyContext);
  const [mapDetails,setMapDetails] = useState();
  var company_id_val = companyDetails && companyDetails.company_id;
  useEffect(() => {
      (async function () {
          try {
              if (company_id_val) {
                  var company_id = companyDetails.company_id
                  var template_id = companyDetails.template_id
                  const mapData = await fetch(
                      config.apiEndpoint + '/wb-frontview/get-frontview-map-data/' + company_id + '/' + template_id
                  );
                  const result = await mapData.json();
                  setMapDetails({"hotel_details":result && result.hotel_details});

              }
          } catch (e) {
              console.error(e);
          }
      })();
  }, [company_id_val])


  useEffect(() => {
    
    if(mapDetails && mapDetails.hotel_details){
      let lat=parseFloat(mapDetails.hotel_details.latitude);
      let lng =parseFloat(mapDetails.hotel_details.longitude);

 
      const initializeMap = ({ setMap, mapContainer }) => {
        const map = new mapboxgl.Map({
          container: mapContainer.current,
          style: "mapbox://styles/mapbox/streets-v11", 
          center: [lng,lat],
          zoom: 13
        });
  
        map.on("load", () => {
          setMap(map);
          map.resize();
        });
  
        var marker = new mapboxgl.Marker()
          .setLngLat([lng,lat])
          .addTo(map);
      };
  
      if (!map) initializeMap({ setMap, mapContainer });
    }

  }, [map,mapDetails]);

  return (
    <div className="container-fluid google-map-wrap">
      <div className="row">
        <div ref={el => (mapContainer.current = el)} style={styles} />
      </div>
    </div>

  )
  
  
};

export default MapBoxComponent;
