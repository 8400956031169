import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json'
import Modal from 'react-modal';

function NewsletterSection() {

    const [companyDetails] = useContext(CompanyContext);
    const [newsletterSectionData, setNewsletterSectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    const newsletterSection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-newsletter-section-data/' + company_id + '/' + template_id
                    );                    
                    const result = await newsletterSection.json();
                    
                    setNewsletterSectionData({ "newsletter":result && result.newsletter,"hotel_id":result && result.hotel_id,"default_value": result && result.default_value.default_value });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    let websitefont = '';


    const [modalshow, setModal] = useState((JSON.parse(sessionStorage.getItem('modalvalue')) !== null) ? JSON.parse(sessionStorage.getItem('modalvalue')) : true);
    const [name, setname] = useState('');
    const [validname, setvalidname] = useState(false);
    const [requiredname, setrequiredname] = useState(false);
    const [email, setemail] = useState('');
    const [validemail, setvalidemail] = useState(false);
    const [requiredemail, setrequiredemail] = useState(false);
    const [number, setnumber] = useState('');
    const [validnumber, setvalidnumber] = useState(false);
    const [requirednumber, setrequirednumber] = useState(false);
    const [successmsg, setsuccessmsg] = useState(false);
    const [thankYou,setThankYou]= useState(false);
    var message='';

    const contactform = () => {

        if (name.length === 0) {
            setrequiredname(true)
        }
        else {
            setrequiredname(false)
        }
        if (email.length === 0) {
            setrequiredemail(true)
        }
        else {
            setrequiredemail(false)
        }
        if (number.length === 0) {
            setrequirednumber(true)
        }
        else {
            setrequirednumber(false)
        }
        if (name.length !== 0 && email.length !== 0 && number.length !== 0) {
            setrequiredname(false)
            setrequiredemail(false)
            setrequirednumber(false)
            fetch(config.apiEndpoint + '/userwb/send-user-query',
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "hotel_id":newsletterSectionData && newsletterSectionData.hotel_id,
                        "name": name,
                        "email": email,
                        "mobile": number,
                        "message": message

                    })
                })
                .then((response) => response.json())
                .then((responseJson) => {
                    setsuccessmsg(true)
                    setThankYou(true);
                    setTimeout(function()
                    { setModal(false); 
                }, 5000);

                })

                fetch(config.apiEndpoint + '/insert-customer-data',
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "hotel_id":newsletterSectionData && newsletterSectionData.hotel_id,
                    "user_name": name,
                    "user_email_id": email,
                    "user_contact_number": number,
                    "user_message": message
                })
            })
            .then((response) => response.json())
            .then((responseJson) => {
            })
            }

    }

    const validateName = (name) => {
        setname(name)
        if (name.match(/[A-Za-z]{2,}/)) {
            setvalidname(false)
        }
        else {
            setvalidname(true)
        }


    }
    const validateEmail = (email) => {
        setemail(email)
        if (email.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
            setvalidemail(false)
        }
        else {
            setvalidemail(true)
        }
    }
    const validateNumber = (number) => {
        setnumber(number)
        if (number.match(/^(\+\d{1, 2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/)) {
            setvalidnumber(false)
        }
        else {
            setvalidnumber(true)
        }
    }
    useEffect(() => {
        sessionStorage.setItem('modalvalue', modalshow)
    }, [modalshow]);
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            minwidth: "360px",
            width:'60%'
        }
    };
    Modal.setAppElement('#root1')
    return (

        <div>
            {newsletterSectionData && newsletterSectionData.default_value && newsletterSectionData.default_value === 'newsletter' && newsletterSectionData.newsletter &&
                <Modal
                    isOpen={modalshow}
                    onRequestClose={() => setModal(false)}
                    style={customStyles}
                    contentLabel="Example Modal"
                >                
                   {(thankYou===false)? <div><i className="fa fa-close pull-right" onClick={() => setModal(false)}></i>
                   
                    <div className="container-popup">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-7">
                                    <div className="popup-img-wrap">
                                        <img className="img-responsive" src={config.wbImagesEndpoint + '/' + newsletterSectionData.newsletter.image} alt={newsletterSectionData.newsletter.image_alt} />
                                    </div>
                                </div>
                                <div className="col-sm-5">
                                    <form>
                                        <div className="popup-heading-text">{newsletterSectionData.newsletter.header}</div>
                                        <div className="pop-heading-smalltext">{newsletterSectionData.newsletter.sub_header}</div>
                                        <div className="modern-form">
                                            <fieldset className='float-label-field'>
                                                <label htmlFor="txtName">Name</label>
                                                <input id="txtName" name="name" value={name} onChange={(e) => validateName(e.target.value)} />
                                                <div hidden={validname === false} style={{ color: 'red' }}>Invalid Name</div>
                                                <div hidden={requiredname === false} style={{ color: 'red' }}>Required</div>
                                            </fieldset>

                                            <fieldset className='float-label-field'>
                                                <label htmlFor="txtEmail" >Email Id</label>
                                                <input id="txtEmail"  name="email"  value={email} onChange={(e) => validateEmail(e.target.value)} />
                                                <div hidden={validemail === false} style={{ color: 'red' }}>Invalid Email</div>
                                                <div hidden={requiredemail === false} style={{ color: 'red' }}>Required</div>
                                            </fieldset>

                                            <fieldset className='float-label-field'>
                                                <label htmlFor="txtPassword">Contact No.</label>
                                                <input id="txtPassword" name="number" value={number} onChange={(e) => validateNumber(e.target.value)} />
                                                <div hidden={validnumber === false} style={{ color: 'red' }}>Invalid Number</div>
                                                <div hidden={requirednumber === false} style={{ color: 'red' }}>Required</div>
                                            </fieldset>

                                            <fieldset className='float-label-field'>
                                                <button type="button" className="book_now_btn" style={{ border: "#b62a51", width: "100%", color:"#fff" }} onClick={() => contactform()}
                                                >Send</button>
                                                <br />
                                                <div hidden={successmsg === false} style={{ color: 'red', fontSize: 15, fontWeight: 'bold' }}>Submitted Successfully !</div>
                                            </fieldset>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>:
                    <div className="text-center">
                        <h3 style={{color:"green"}}>Thank you !!! </h3>
                        <h4>We will get in touch with you shortly.</h4>
                    </div>}
                </Modal>
            }
            </div>
            );    
}

export default NewsletterSection;